jQuery(window).load(function() {

	/* Create responsive background images */
	initVisuals();

	/* Give elements an equal height */
	equalHeight($('#nieuws .nieuws-grid .item'));
	equalHeight($('#werkplaats-acties .actie-grid .item'));

	if (typeof createOTM == 'function') {
		var parts = window.location.hostname.split('.').slice(-2)
		createOTM('[data-dtcmedia-otm]', {
			host: parts.join('.')
		});
	}

	if ($('.js-proefrit-vestiging select').val() !== '' && getURLParameter('vestiging') !== '') {
		$('.js-proefrit-vestiging select').parents('li.gfield').hide();
	}

	/**
	 * Isotope
	 */
	if($('.isotope').length){
		var foo = [];
		$('select:visible option:selected').each(function(i, selected){
			foo[i] = $(selected).attr('data-filter-value');
		});

		var $container = $('.isotope').isotope({
			filter: foo[1] + foo[2],
			itemSelector: 'li',
			layoutMode: 'fitRows',
			sortAscending: true,
			getSortData: {
				number: '.price parseInt'
			},
			// sort by color then number
			sortBy: [ 'number' ]
		},filters = {});

		// display message box if no filtered items
		if (!$container.data('isotope').filteredItems.length ) {
			$('.js-isotope-geen-resultaten').fadeIn(500);
		}else{
			$('.js-isotope-geen-resultaten').fadeOut(5);
		}



		// filter buttons
		$('select').change(function(){
			var $this = $(this);

			// store filter value in object
			// i.e. filters.color = 'red'
			var group = $this.attr('data-filter-group');

			filters[ group ] = $this.find(':selected').attr('data-filter-value');

			sort = $this.find(':selected').attr('data-filter-sort');

			if(sort == 'desc'){
				var sortDataBy = false
			}else{
				var sortDataBy = true
			}

			// convert object into array
			var isoFilters = [];
			for ( var prop in filters ) {
				isoFilters.push( filters[ prop ] )
			}
			var selector = isoFilters.join('');
			$container.isotope({
				filter: selector,
				sortAscending: sortDataBy,
				getSortData: {
					number: '.price parseInt'
				},
				sortBy: [ 'number' ]
			});

			// display message box if no filtered items
			if (!$container.data('isotope').filteredItems.length ) {
				$('.js-isotope-geen-resultaten').fadeIn(500);
			}else{
				$('.js-isotope-geen-resultaten').fadeOut(5);
			}

			return false;
		});
	}

	$('#banner.private-lease .header').click(function(event) {
		if($(event.target).is('.header') && $(this).parent('#banner.private-lease').hasClass('open')) {
			$('#banner.private-lease .header li.active').trigger('click');
		}
	});
	$('#banner.private-lease .header li').click(function(){
		// Voeg open class toe aan banner
		$('#banner.private-lease').toggleClass('open dicht');

		// Nummer geklikte item
		var nummer = $(this).index();

		// Kijken of geklikte item active is
		if ($(this).hasClass('active')) {

			// Verwijdere active en open class als er op active item geklikt is
			$(this).removeClass('active');

			$('#banner.private-lease .header li p').slideDown();

			// Sluit li als er op active li geklikt is
			$('#banner.private-lease .content li:eq('+nummer+')').removeClass('active').slideUp();
		}else{
			// Voegen en verwijder active class van li
			$('#banner.private-lease .header li').removeClass('active');
			$(this).addClass('active');

			$('#banner.private-lease .header li p').slideUp();

			// Open en klap li dicht
			$('#banner.private-lease .content li:not(:eq('+nummer+'))').slideUp(function() {
				$('#banner.private-lease .content li:eq('+nummer+')').addClass('active').slideDown();
			});
		}
	});

});

jQuery(function($) {

	/* Initiate some plug-ins */
	setPadding();

	$('#nav').doubleTapToGo();

	/* Filter brochures */
	$('[data-brochures]').click(function(event) {
		event.preventDefault();
		var soort = $(this).data('brochures');
		$('#brochures .items .item:not(.' + soort + ')').fadeOut();
		$('#brochures .items .item.' + soort).fadeIn();
	});

	/**
	 * Mobiel filter voor prive lease overzicht
	 */
	$('.js-filter-toggle').click(function(){
		$(this).next('form').slideToggle();
		$("html, body").animate({ scrollTop: $(this).offset().top - 100 }, 400);
	});

	/* Scroll to ID */
	$('[data-scroll]').click(function(event) {
		if($(this).is('[data-scroll=page]')) {
			var header = $('#topbar:visible').outerHeight() + $('#header:visible').outerHeight();
			event.preventDefault();
			var hash = $(this).attr('href');
			var top  = hash === "#" ? 0 : $(hash).offset().top - header;
			$('html, body').stop().animate({
				scrollTop: top
			}, 1200);
		}
		if($(this).is('[data-scroll=top]')) {
			event.preventDefault();
			$('html, body').stop().animate({
				scrollTop: 0
			}, 1200);
		}
	});


	/* Slick slider */
	var $sliders = $('.slick-slider, .slider ul, .showcase ul, .slides');

	$sliders.on('init', function(event, slick) {
		var slides = slick.$slides;

		if(!(slides.length > 1)) {
			$('.swipe-icon').css('visibility','hidden');
		}
	});

	$('#banner .slider ul').slick({
		slide: 'li',
		fade: false,
		swipe: false,
		touchMove: true,
		infinite: false,
		autoplay: true,
		autoplaySpeed: 5000,
		speed: 800,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		dotsClass: 'dots',
		appendDots: '.black-box',
		customPaging: function(slider, i) {
			return '<a>' + (i + 1) + '</a>';
		}
	});
	$('#banner .dots').wrap('<div class="dots-wrap"><div class="flex"></div></div>');

	$('.business__slider ul').slick({
		slide: 'li',
		fade: false,
		swipe: false,
		touchMove: true,
		infinite: false,
		autoplay: true,
		autoplaySpeed: 5000,
		speed: 800,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: false,
	});

	$('#lease-galerij .slider ul').slick({
		slidesToShow: 8,
		slidesToScroll: 1,
		dots: false,
		slide: 'li',
		focusOnSelect: false,
		appendArrows: '#lease-galerij .slider',
		prevArrow: '<span class="slider-nav prev hide-for-touch"><i><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 56" enable-background="new 0 0 30 56" xml:space="preserve"><path d="M5.4,28L30,2.7L27.3,0L0,28l27.3,28l2.7-2.7L5.4,28z"/></svg></i></div>',
		nextArrow: '<span class="slider-nav next hide-for-touch"><i><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 56" enable-background="new 0 0 30 56" xml:space="preserve"><path d="M0,53.3L2.7,56L30,28L2.7,0L0,2.7L24.6,28L0,53.3z"/></svg></i></div>',
		responsive: [{
			breakpoint: 1024,
			settings: {
				slidesToShow: 6
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 4
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 2
			}
		}]
	});

	$('#model-galerij .showcase ul').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: false,
		swipe: true,
		slide: 'li',
		infinite: false,
		asNavFor: '#model-galerij .thumbs ul',
		touchMove: true
	});

	$('#model-galerij .thumbs ul').slick({
		slidesToShow: 8,
		slidesToScroll: 1,
		asNavFor: '#model-galerij .showcase ul',
		dots: false,
		arrows: false,
		slide: 'li',
		infinite: false,
		focusOnSelect: true,
		touchMove: false,
		responsive: [{
			breakpoint: 1024,
			settings: {
				slidesToShow: 6
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 4
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 3,
				touchMove: true,
				centerMode: true,
				centerPadding: 0
			}
		}]
	});

	$('.touch .occasions.detail .model-afb .slides').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		slide: 'li',
		swipe: true,
		touchMove: true
	});

	$('.occasions.detail #carousel-nav .slides').slick({
		slide: 'li',
		slidesToShow: 8,
		slidesToScroll: 1,
		swipe: false,
		touchMove: false,
		infinite: false,
		focusOnSelect: true,
		prevArrow: '<span class="slider-nav prev hide-for-touch"><i><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 56" style="enable-background:new 0 0 30 56;" xml:space="preserve"><path style="fill:#D3CBC5;" d="M5.396,27.312l24.59-24.668L27.341,0L0.052,27.286l27.289,27.289L30,51.917L5.396,27.312z"/></svg></i></div>',
		nextArrow: '<span class="slider-nav next hide-for-touch"><i><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 56" style="enable-background:new 0 0 30 56;" xml:space="preserve"><path style="fill:#D3CBC5;" d="M0.052,51.917l2.659,2.657L30,27.286L2.711,0L0.066,2.645l24.59,24.668L0.052,51.917z"/></svg></i></div>',
		responsive: [{
			breakpoint: 1024,
			settings: {
				slidesToShow: 6
			}
		},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3
				}
			}
		]
	});

	$('.slick-slider').on('swipe', function(event, slick, direction){
		$('.swipe-icon').css('visibility','hidden');
	});

	/* Responsive videos */
	$(".fluid-video").fitVids();


	/* One page navigation */
	$(window).scroll(function(){
		setNavActiveState();
	});


	/* Equal heights */
	function equalHeight(group) {
		var height = 0;
		group.each(function() {
			element = $(this).outerHeight();
			if(element > height) {
				height = element;
			}
		});
		group.height(height);
	};


	/* Menu trigger */
	$('.trigger-menu').click(function(event){
		event.preventDefault();
		$('#nav').slideToggle();
		$('.trigger-menu i').toggleClass('fa-close fa-bars');
	});


	/* Magnific popup */
	$('.lightbox, .lightbox a, .gallery a, #carousel-album a').magnificPopup({
		type: 'image',
		mainClass: 'mfp-with-zoom',
		tClose: 'Sluiten (Esc)',
		tLoading: 'Laden...',
		zoom: {
			enabled: true,
			duration: 300,
			easing: 'ease-in-out',
			opener: function(openerElement) {
				return openerElement.is('img') ? openerElement : openerElement.find('img');
			}
		},
		gallery:{
			enabled: true,
			tPrev: 'Vorige (Linker pijl toets)',
			tNext: 'Volgende (Rechter pijl toets)',
			tCounter: '%curr% van %total%'
		}
	});

	/* Magnific popup */
	$('#page .content').find('a[href$=".jpg"], a[href$=".png"]').magnificPopup({
		type: 'image',
		mainClass: 'mfp-with-zoom',
		tClose: 'Sluiten (Esc)',
		tLoading: 'Laden...'
	});

	$('.movie-link').magnificPopup({
		type: 'iframe',
		mainClass: 'mfp-with-zoom',
		tClose: 'Sluiten (Esc)',
		tLoading: 'Laden...'
	});


	$('.ajax-popup-link').magnificPopup({
		type: 'iframe',
		callbacks: {
			open: function(){
				$('iframe').iFrameResize();
			}
		}
	});

	/* Form validation */
	$('form').validate({
		errorPlacement: function(error, element) {
			if (element.attr('name') == 'model'){
				error.insertAfter('.custom.dropdown');
			} else if (element.attr('name') == 'cv'){
				error.insertAfter('.cv');
			} else if (element.attr('name') == 'brief'){
				error.insertAfter('.brief');
			} else {
				error.insertAfter(element);
			}
		}
	});


	/* File input */
	$('li.file input[type="text"].cv').click(function(){
		$('#cv').trigger('click').change(function (){
			var filename = $('#cv').val().split('\\').pop();
			$('.cv').val(filename);
		})
	});
	$('li.file input[type="text"].brief').click(function(){
		$('#brief').trigger('click').change(function (){
			var filename = $('#brief').val().split('\\').pop();
			$('.brief').val(filename);
		})
	});


	/* Highlighting auto complete */
	$.ui.autocomplete.prototype._renderItem = function (ul, item) {
		var term = this.term.split(' ').join('|');
		var re = new RegExp("(" + term + ")", "gi");
		var t = item.label.replace(re, "<b>$1</b>");
		return $("<li></li>")
			.data('item.autocomplete', item)
			.append("<a>" + t + "</a>")
			.appendTo(ul);
	};


	/* Search auto complete */
	$('form.site_zoeken input#zoeken').autocomplete({
		source: dtcmediaoccasionmodule.ajaxurl+"?action=site_search_autocomplete",
		minLength: 2,
		messages: {
			noResults: '',
			results: function() {}
		},
		open: function() {
			$('.ui-autocomplete:visible').css({top:"+=15"});
		},
		select: function(event, ui) {
			$(event.target).val(ui.item.value);
			this.form.submit()
			return false;
		}
	});

	$('form.zoeken').submit(function(e) {
		if($(this).find('input[type=search]').val().length < 3) {
			e.preventDefault();
			$(this).addClass('error');
			$('form.zoeken label.errorTekst').fadeIn().delay(3000).fadeOut();
		}
	});


	/* Submenu's */
	var delaySub;
	$('.submenu').parent('li').addClass('has-submenu');
	$('.submenu', '.has-submenu.modellen').addClass('modellen');
	$('.has-submenu, .submenu').hover(function() {
		clearTimeout(delaySub);
		$('.submenu').removeClass('active');
		$('.submenu', $(this)).addClass('active');
	}, function() {
		clearTimeout(delaySub);
		delaySub = setTimeout(function(){
			$('.submenu').removeClass('active');
		}, 1000);
	});


	/* Tabs's */
	$('.tabs').each(function(){
		// For each set of tabs, we want to keep track of
		// which tab is active and it's associated content
		var $active, $content, $links = $(this).find('a');

		// If the location.hash matches one of the links, use that as the active tab.
		// If no match is found, use the first link as the initial active tab.
		$active = $($links.filter('[href="'+location.hash+'"]')[0] || $links[0]);
		$active.addClass('active');

		$content = $($active[0].hash);

		// Hide the remaining content
		$links.not($active).each(function () {
			$(this.hash).addClass('hidden');
		});

		// Bind the click event handler
		$(this).on('click', 'a', function(e){
			// Make the old tab inactive.
			$active.removeClass('active');
			$content.addClass('hidden');

			// Update the variables with the new link and content
			$active = $(this);
			$content = $(this.hash);

			// Make the tab active.
			$active.addClass('active');
			$content.removeClass('hidden');

			// Prevent the anchor's default click action
			e.preventDefault();
		});
	});
});

$(window).on('resize', function() {
	initVisuals();
})

function occasionmodule_after_merk_refresh() {}

function financieringsmodule_select_refresh(field) {}
